<template>
  <div class="prescription-models-list-view">
    <app-header icon="table" title="Modelos de Receituário"></app-header>

    <section>
      <div class="card">
        <header class="card-header">
          <div class="card-header-title"></div>
          <div class="card-header-buttons">
            <router-link :to="{ name: 'prescription-models.create' }">
              <b-tooltip label="Novo Modelo">
                <b-button type="is-primary" size="is-small" icon-left="plus">
                  Adicionar
                </b-button>
              </b-tooltip>
            </router-link>
          </div>
        </header>

        <div class="card-content">
          <b-table
            @page-change="onPageChange"
            :data="data"
            :total="total"
            :per-page="15"
            :loading="isLoading"
            paginated
            backend-pagination
            aria-next-label="Próxima Página"
            aria-previous-label="Página Anterior"
            aria-page-label="Página"
            aria-current-label="Página Atual"
          >
            <b-table-column v-slot="props" field="patient" label="Nome">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column v-slot="props" field="created_at" label="Criado em">
              {{ props.row.created_at | date }}
            </b-table-column>

            <b-table-column v-slot="props" label="Opções">
              <router-link
                :to="{
                  name: 'prescription-models.edit',
                  params: { id: props.row.id },
                }"
              >
                <b-tooltip label="Editar">
                  <b-button
                    type="is-secondary"
                    size="is-small"
                    icon-right="pencil-alt"
                  />
                </b-tooltip>
              </router-link>
              <b-tooltip label="Excluir">
                <b-button
                  @click="onClickDelete(props.row.id)"
                  type="is-danger"
                  size="is-small"
                  icon-right="trash"
                  class="ml-2"
                />
              </b-tooltip>
            </b-table-column>

            <template slot="empty">
              <section class="section">
                <div class="content has-text-grey has-text-centered">
                  <p>
                    <b-icon icon="frown" size="is-large"></b-icon>
                  </p>
                  <p>Nenhum modelo encontrado.</p>
                </div>
              </section>
            </template>
          </b-table>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PrescriptionService from '@/services/prescriptions.service';

export default {
  data: () => ({
    isLoading: true,
    data: [],
    total: null,
    page: 1,
  }),
  methods: {
    loadModels() {
      this.isLoading = true;

      PrescriptionService.getModels({
        page: this.page,
      })
        .then(({ data }) => {
          const { total, data: items, current_page } = data;
          this.total = total;
          this.data = items;
          this.page = current_page;
        })
        .finally(() => setTimeout(() => (this.isLoading = false), 300));
    },
    onPageChange(page) {
      this.page = page;
      this.loadModels();
    },
    onClickDelete(id) {
      this.$buefy.dialog.confirm({
        message:
          'Deseja realmente excluir este modelo? Essa ação não poderá ser desfeita.',
        onConfirm: () => this.deleteProcedure(id),
      });
    },
    deleteProcedure(id) {
      this.isLoading = true;

      PrescriptionService.destroyModel(id)
        .then(({ data: modelId }) => {
          this.$buefy.snackbar.open('Modelo excluído com sucesso.');
          this.data = this.data.filter((model) => model.id !== modelId);
        })
        .catch(() =>
          this.$buefy.snackbar.open('Erro ao tentar excluir modelo.')
        )
        .finally(() => (this.isLoading = false));
    },
  },
  mounted() {
    this.loadModels();
  },
};
</script>
